<i18n lang="json">
{
	"ru": {
    "nights": "менее суток | {n} сутки | {n} суток | {n} суток",
    "for": "Итого за",
    "prepayment": "Предоплата {prepay} %",
    "saving": "экономия {saving}"
	},
	"en": {
    "nights": "less night | {n} night | {n} nights | {n} nights",
    "for": "for",
    "prepayment": "{prepay} % prepayment",
    "saving": "saving {saving}"
	}
}
</i18n>

<template>
  <div class="view-price" :class="{ 'price-button': isPriceButton }">
    <div v-if="!hotSale && !hotInterval" class="view-price__calc">
      <div class="view-price__text tmp-font--medium">
        <span>{{ t("for") }} {{ t("nights", Number(price.cnt || 1)) }}</span>
        <span v-if="isPriceButton">:</span>
      </div>
      <div 
        class="view-price__value" 
        :class="{ 
          'tmp-font--average_big_bold': !isPriceButton,
          'tmp-font--medium_bold': isPriceButton 
        }" 
        @click="emits('clickPrice')"
      >
        <div v-if="meals" class="view-price__meals">{{meals}}</div>
        <div class="view-price__item">
          <span class="view-price__price-old" v-if="salePrice < oldPrice">{{ maxPrice }}</span>
          <span
            :class="{
              'view-price__sale-price': salePrice < oldPrice,
              'view-price__price': salePrice < oldPrice
            }"
          >{{ totalPrice }}</span>
        </div>
      </div>
    </div>
    <div v-else class="view-price__calc view-price__calc_sale">
      <div class="view-price__text tmp-font--medium">
        <span>{{ t("for") }} {{ t("nights", Number(price.cnt || 1)) }}</span>
        <span v-if="isPriceButton">:</span>
      </div>
      <div 
        class="view-price__value" 
        :class="{ 
          'tmp-font--average_big_bold': !isPriceButton,
          'tmp-font--medium_bold': isPriceButton 
        }"
        @click="emits('clickPrice')"
      >
        <div v-if="meals" class="view-price__meals">{{meals}}</div>
        <div class="view-price__item">
          <span class="view-price__sale-percent">%</span>
          <span class="view-price__price-old" v-if="salePrice < oldPrice">{{ maxPrice }}</span>
          <span
            :class="{
              'view-price__sale-price': salePrice < oldPrice,
              'view-price__price': salePrice < oldPrice
            }"
          >{{ totalPrice }}</span>
        </div>
      </div>
      <div class="view-price__economy">
        {{ t("saving", { saving: formatPriceCeil(totalEconomy, currency, locale) }) }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import { formatPriceCeil, formatPriceCeilForCurrency } from "@/utils/format";

const emits = defineEmits(['clickPrice']);
const props = defineProps({
  price: {
    type: Object,
  },
  currency: {
    type: Object,
    default: {},
  },
  isPriceButton: {
    type: Boolean,
    default: false
  },
  oldPrice: {
    type: Number,
    default: 0
  },
  salePrice: {
    type: Number,
    default: 0
  }
});

const currency = computed(() => props.currency?.title || 'RUB');

const { t, locale } = useI18n();

const meals = inject('meals', null);

const maxPrice = computed(() => formatPriceCeilForCurrency(props.oldPrice, currency.value, locale.value));
const totalPrice = computed(() => formatPriceCeilForCurrency(props.salePrice, currency.value, locale.value));

const hotSale = computed(() => {
  const sale = props.price?.detail
    ? Object.values(props.price?.detail)?.find(item => item.tp === 'sale')
    : null;
  return sale || null;
});

const hotInterval = computed(() => {
  const interval = props.price?.detail
    ? Object.values(props.price?.detail)?.find(item => item.tp === 'interval')
    : null
  return interval || null;
});

const hotSaleEconomy = computed(() => hotSale.value ? hotSale.value.cost_old - hotSale.value.cost : null);

const hotIntervalEconomy = computed(() => {
  return hotInterval.value
    ? (hotInterval.value.base - hotInterval.value.cost) * hotInterval.value.cnt
    : null
});

const totalEconomy = computed(() => hotSaleEconomy.value + hotIntervalEconomy.value);
</script>

<style lang="scss" scoped>
.price-button {
  .view-price {
    &__calc {
      justify-content: flex-start;
    }
    &__value {
      color: #2d6cb4;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
.view-price {
  &__value{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }
  &__meals{
    margin-right: 10px;
    padding: 3px 10px;
    // white-space: nowrap;
    font-size: 9px;
    color: #0659ff;
    border: solid 1px #0659ff;
    border-radius: 20px;
    text-align: center;
  }
  &__calc {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_sale {
      position: relative;
      .view-price {
        &__value {
          color: #e10600;
        }
        &__sale-percent {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 1px;
          width: 20px;
          height: 16px;
          background: #e10600;
          border-radius: 2px;
          font-weight: bold;
          color: #fff;
          font-size: 12px;
          padding: 3px 5px;
          line-height: 16px;
          margin-right: 5px;
        }
        &__economy {
          position: absolute;
          bottom: -24px;
          right: 0;
          color: #717171;
        }
      }
    }
  }
  &__item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  &__price-old {
    margin-right: 9px;
    color: #717171;
    &::before {
      content: '';
      display: block;
      width: calc(100% + 5px);
      border-top: 1px solid #ff0000;
      transform: rotate(-13deg) translate(-5px, 10px);
    }
  }
  &__sale-price {
    color: #e10600;
  }
}
</style>
