<template>
  <div
    class="warning"
    :class="{
      'warning_vertical-small': vertical == 'small',
      'warning_horizontal-small': horizontal == 'small',
      'warning_color-coral': color == 'coral',
      'warning_color-pink': color == 'pink',
      'warning_color-blue': color == 'blue',
      'warning_color-light_blue': color == 'light_blue',
      'warning_color-dark_pink': color == 'dark_pink',
      'disabled': disabled,
      'no-center': !center,
      'pre-line': preLine
    }"
  >
    <div v-if="icon" class="warning__icon">
      <span :class="`icon-app-${icon}`"></span>
    </div>
    <div class="warning__text" v-if="text && text !== ''">
      <p>{{ text }}</p>
    </div>
    <div class="warning__text" v-else>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Warning",
  props: {
    icon: {
      type: [String, Boolean],
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    vertical: {
      type: String,
      default: null,
    },
    horizontal: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: true
    },
    preLine: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.warning {
  background: #ededed;
  width: 100%;
  padding: 20px;
  margin-top: 10px;

  display: flex;
  align-items: center;

  &_vertical {
    &-small {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  &_horizontal {
    &-small {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &_color-pink {
    background: #fff6e5;
  }
  &_color-coral {
    background: #fff6c4;
  }
  &_color-blue {
    background:#f0f6ee;
  }
  &_color-dark_pink {
    background:#fee7ec;
  }
  &_color-light_blue {
    background:#e4f3ff;
  }

  &__icon {
    display: flex;
    align-items: center;
    flex-basis: 40px;

    span {
      font-size: 28px;
      color: #b2b2b2;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    flex-grow: 1;
    color: #000;
  }
  &.disabled {
    background: #fdd2d2;
  }
}

.no-center {
  .warning {
    &__text {
      align-items: flex-start;
    }
  }
}
.pre-line {
  .warning {
    &__text {
      white-space: pre-line;
    }
  }
}
p {
  margin: 0;
  &:not(:first-child){
    margin-top: 10px;
  }
}
</style>
